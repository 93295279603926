import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

const resources = {
    en: {
        translation: {
            locationMap: {
                version: 'Version',
                freeOfChargeConnector: 'Free of Charge',
                privacySettings: 'Privacy Settings',
                initializing: 'Initializing',
                camera: 'Camera',
                selectCamera: 'Select camera',
                showLocationsList: 'Show Locations List',
                hideLocationsList: 'Hide Locations List',
                search: 'Search...',
                enableGeolocation: 'In order to enable Location Map, please allow geolocation permission. If you already granted the permission, please wait a couple of minutes.',
                currentlyCharging: 'Currently charging',
                greenWay: 'GreenWay',
                noResults: 'Sorry, no results were found.',
                loading: 'Loading...',
                loadMore: 'load more',
                km: 'km',
                kW: 'kW',
                kWh: 'kWh',
                open24_7: 'OPEN 24/7',
                openUntil: 'open until',
                openFrom: 'open from',
                from: 'From',
                open: 'OPEN',
                closed: 'CLOSED',
                close: 'close',
                outOfOrder: 'Temporary out of order',
                chargers: 'chargers',

                // Confirmation dialog - places
                yesExit: 'Yes, Exit',
                exitSearchedLocation: "Exit searched location?",
                confirmationPlaceDialogText: 'Are you sure you want to exit results based on searched location point?',

                //weekdays
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',

                // Current State
                after: 'after',
                cancel: 'Cancel',
                batteryState: 'Battery State',
                currentState: 'Current State',
                endChargingSession: 'End charging session',
                confirmAction: 'Confirm action',
                confirmActionText: 'Are you sure you want to  terminate the charging session?',
                yesTerminate: 'Yes, Terminate',
                chargingTime: 'Charging Time',
                consumedEnergy: 'Consumed Energy',
                chargingPower: 'Charging Power',
                chargingPowerInfo: "Charging power info",
                chargingPowerInfoText: "Possible reasons: *Your vehicle is single phase, but charge point supports three phase charging *Your vehicle max charging power is lower than charge point max power *At the end of charging your vehicle is probably balancing battery or finishing charging *Power can be decreased since EV batteries are overheated *Power management on the level of the charger could be in progress.",
                ok: 'OK',
                moreInfo: 'More info',

                // User menu
                menu: 'Menu',
                languageSettings: 'Language Settings',
                clientZone: 'Client Zone Portal',
                greenWayWebsite: 'GreenWay Website',
                greenWayWebsiteUrl: 'https://greenway.sk/',
                greenWayWebsiteUrl_sk: 'https://greenway.sk/',
                greenWayWebsiteUrl_pl: 'https://greenwaypolska.pl/',
                clientZonePortalUrl: 'https://client.greenway.sk/',
                clientZonePortalUrl_sk: 'https://client.greenway.sk/',
                clientZonePortalUrl_pl: 'https://client.greenwaypolska.pl',
                email: 'Email',
                phone: 'Phone',
                phoneNumber: '+421 2 330 56 236',
                phoneNumber_sk: '+421 2 330 56 236',
                phoneNumber_pl: '+48 58 325 10 77',
                emailAddress: 'info@greenway.sk',
                emailAddress_sk: 'info@greenway.sk',
                emailAddress_pl: 'bok@greenwaypolska.pl',
                skOption: 'Slovak',
                plOption: 'Polish',
                enOption: 'English',

                // ListOfConnectors
                connector: 'Connector',
                available: 'Available',
                charging: 'Charging',
                occupied: 'Occupied',
                offline: 'Offline',
                maintenance: 'Maintenance',
                chaDeMo: 'ChaDeMo',
                ccs: 'CCS',
                type2c: 'Type2 Cable',
                type2: 'Type2 Socket',
                unknown: 'Unknown',
                listOfConnectors: 'List of connectors',
                charger: 'Charger',

                // LocationDetails
                locationDetails: 'Location details',
                locationName: 'Location name',
                locationOwner: 'Location owner',
                openingHours: 'Opening hours',
                priceList: 'Price list',
                showDetails: 'Show details',

                // OpeningHours
                date: 'Date',
                openingTime: 'Opening time',

                // TopSection
                scanQR: 'Scan QR',
                navigate: 'Navigate',
                accessInstructions: 'Access instructions',

                // AccessInstructions
                accessInfo: 'Access info',

                // CurrentLocation
                currentLocation: 'current Location',

                // Map - Filter
                filterOptions: 'Filter options',
                maxPower: 'Max. Power',
                connectorTypes: 'Connector Types',
                ccsPlug: 'CCS plug',
                chaDeMoPlug: 'ChaDeMo plug',
                type2Socket: 'Type2 Socket',
                type2Plug: 'Type2 plug',
                others: 'Others',
                freeOfChargeOnly: 'Free of charge only',
                freeOfCharge: 'Free of Charge',
                showRoamingStations: 'Show roaming stations',
                showPartnerStations: 'Show partner stations',
                resetToDefaults: 'Reset to defaults',
                applyAndClose: 'Apply & Close',

                // Map - MapTypeController
                changeLayer: 'Change layer',
                mapLayer: 'Map layer',
                roadMap: 'Roadmap',
                satellite: 'Satellite',
                terrain: 'Terrain',
                traffic: 'Traffic',

                // ScanQR
                scanQRonCharger: 'Scan QR on charger',
                scanQRText: 'To start charging session, you need to select camera first and then point your phone camera at the QR code placed on charger.',
                or: 'or',
                typeCodeManually: 'Type code manually',
                typeChargerQRCode: 'Type charger QR code',
                searchInput: 'Search Input',

                // SearchLocation
                noSearchResults: 'Sorry, no connectors with this code were found. Please check the code for mistyped letters.'
            }
        }
    }
}

i18n.use(initReactI18next)
    .init({
        legacy: false,
        resources,
        fallbackLng: "en",
        lng: 'en',
        interpolation: {
            escapeValue: false
        },
        silentTranslationWarn: true
    })

export default i18n